body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121d27;
}

div.App {
  height: 95vh
}

html {
  height: 100vh;

  body {
    height: 100%;

    div#root {
      height: 100%;

      div.App {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

div.echarts-for-react {
  flex-grow: 1;
}

.styled-icon {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 8px;
  border-radius: 100%;
  background: rgb(123, 238, 91);
  margin-left: 20px;
}

.MuiSlider-markLabel {
  color: white !important
}