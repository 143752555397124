.MuiPopover-paper {
    background-color: #263543 !important;
    color: #8a939f !important;
}
.MuiInput-underline:before {
    border-color: #8a939f !important;
}

.MuiInput-underline:after {
    border-color: #8a939f !important;
}

.MuiSelect-icon {
    color: #8a939f !important;
}