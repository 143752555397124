.MuiTableRow-root {
    cursor: pointer;
    background-color: #263543;
    color: #8a939f !important;
    overflow-x: hidden !important;
    border-bottom: 2px solid #121d27 !important;
}

.MuiTableCell-head {
    color: #8a939f !important;
}

.MuiPaper-root {
    overflow-x: hidden;
}

.MuiTableCell-root {
    border-bottom: 1px solid #121d27 !important;
}

.MuiTableCell-body {
    background-color: #263543 !important;
    overflow-x: hidden !important;
}

.MuiTablePagination-toolbar {
    background-color: #263543;
    color: #8a939f !important;
    overflow-x: hidden !important;
    border-bottom: 2px solid #121d27 !important;
}